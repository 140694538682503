import {
  ChangeDetectionStrategy,
  Component
} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import { InputComponent } from '@revolve-app/app/shared/forms/text-inputs/input/input.component';
import {ButtonComponent, TButtonsSizes} from '@revolve-app/app/shared/forms/buttons/button/button.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { TooltipDirective } from '@revolve-app/app/core/utils/tooltip.directive';
import { RouterLink } from '@angular/router';
import { PermissionBasedDirective } from '@revolve-app/app/core/utils/permission-based.directive';
import {TelegramLoginComponent} from "@revolve-app/app/shared/forms/buttons/telegram-login/telegram-login.component";
import {AuthModule} from "@revolve-app/app/core/features/auth/store/auth/auth.module";
import {AuthForm} from "@revolve-app/app/core/features/auth/forms/auth.form";
import {selectIsChangeEnabled} from "@revolve-app/app/core/features/auth/store/auth/auth.reducer";
import {signIn} from "@revolve-app/app/core/features/auth/store/auth/auth.actions";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-auth-signin',
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    AsyncPipe,
    AuthModule,
    TooltipDirective,
    RouterLink,
    TelegramLoginComponent,
    TranslateModule,
  ],
  providers:[
    AuthForm,
    PermissionBasedDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './signin.component.html',
  styleUrl: './signin.component.scss'
})
export class SigninComponent {
  disabled$: Observable<boolean>;
  signInForm: FormGroup;
  constructor(private form: AuthForm,
              private storeService: Store) {
    this.signInForm = this.form.signInForm();
    this.disabled$  = this.storeService.select(selectIsChangeEnabled);
  }

  signIn(): void{
    if (this.signInForm.valid) {
      this.storeService.dispatch(signIn({signInInput: this.signInForm.value,redirectUrl:'/customer'}))
    }
  }

  protected readonly TButtonsSizes = TButtonsSizes;
}
