@if(signInForm){
  <h2 class="text-32 font-medium text-white" i18n>{{'sign_in' | translate}} Xkaex</h2>
  <form [formGroup]="signInForm" >
    <div class='mt-5 relative'>
      <app-input
        [formControlItem]="signInForm.controls['phoneNumber']"
        placeholder="+XXXXXXXXXXX"
        iconPrefix="icon icon-phone text-secondary"
        type="text"
      ></app-input>
    </div>
    <div class='mt-5 relative'>
      <app-input
        [formControlItem]="signInForm.controls['password']"
        placeholder="password"
        i18n-placeholder
        iconPrefix="icon icon-teenyicons_password-solid text-secondary"
        type="password"
      ></app-input>
    </div>

    <div class='mt-1 text-right'>
        <a routerLink='/auth/forgot-password-step-1' class="text-white fs-14">
          {{'forgot_password' | translate}}
        </a>
    </div>

    <div class='mt-5'>
      <app-button text="sign_in" (onClick)='signIn()' [size]="TButtonsSizes.MEDIUM_REGULAR" class='mt-5'>
      </app-button>
    </div>
  </form>
  <div class="grid grid-cols-3 mt-8 mb-8">
    <div class="border-t border-1 border-white"></div>
    <div class="text-center relative">
      <span class="absolute -top-3 text-white" i18n>{{'or' | translate}}</span>
    </div>
    <div class="border-t border-1 border-white"></div>
  </div>
  <div class="mt-4 mb-4">
    <app-telegram-login></app-telegram-login>
  </div>
}
